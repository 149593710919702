body.active-modal {
    overflow-y: hidden;
  }

  .modal {
    z-index: 2;
  }

  .modal,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }

  .overlay {
    background: rgba(49, 49, 49, 0.8);
  }
  .modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    line-height: 2rem;
    padding: 2rem 5rem;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    border-radius: 10px;
  }

  .modal-content h2 {
    color: #dc143c;
    margin-bottom: 1rem;
  }

  .modal-content p {
    color: black;
  }

  .modal-content .modal-links button {
    background: var(--btnColor);
    width: 8rem;
    margin: 1.5rem 0.5rem 0 0;
    border: 1px solid var(--btnText);
    background-size: 200%;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.75rem;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
    transition: 0.5s ease-out;
  }

  .modal-content .modal-links button:hover {
    color: var(--bgColor);
    background: #00bfff;
    border: 1px solid transparent;
  }

  .close-modal {
    background-color: transparent;
    border: none;
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    cursor: pointer;
  }

  .close-modal:hover {
    color: var(--heroColor2);
  }

  @media (max-width: 863px) {
    .modal-content {
      padding: 1.5rem 2.5rem;
    }
    .modal-content p {
      font-size: 0.89rem;
    }
    .modal-content p {
      font-size: 0.89rem;
    }
    .modal-content .modal-links button {
      font-size: 0.75rem;
      width: 5rem;
    }
  }