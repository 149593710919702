@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  scroll-behavior: smooth;
}

:root {
  --navScroll: #131313;
  --heroColor: #fff;
  --heroColor2: #87cefa;
  --headColor: #ffd700;
  --textColor: #dcdcdc;
  --btnText: #fff;
  --btnColor: #ffd700;
  --toolColor: #fff;
  --toolText: #0b2957;
  --cardColor: #fff;
  --cardTitle: #87cefa;
  --bgColor: #0b2957;
  --bgColor2: #131313;
  --lightText: #000000;
  --lightBg: #fff;
  --lightH2: #dc143c;
}

body {
  background: linear-gradient(
    150deg,
    #0b2957 15%,
    rgb(12, 12, 53) 70%,
    #174a69 94%
  );
  font-family: "Roboto";
  overflow-x: hidden;
}

/* Dark mode */
body.dark {
  background: linear-gradient(
    150deg,
    #0b2957 15%,
    rgb(12, 12, 53) 70%,
    #174a69 94%
  );
}
body.dark h1 {
  color: #fff;
  color: var(--heroColor);
}
body.dark p {
  color: #dcdcdc;
  color: var(--textColor);
}
body.dark .navbar a,
.contact-info .row a h2 {
  color: #fff;
  transition: 0.5s ease-out;
}
body.dark .navbar a:hover {
  color: #00bfff;
}
body.dark .modal p {
  color: #000;
}
body.dark .contact-info .subject a {
  color: #dcdcdc;
  color: var(--textColor);
}
body.dark .contact-info .row a h2:hover {
  color: #00bfff;
  transition: 0.5s ease-out;
}
/* Light mode */
/* body.light {
  background: linear-gradient(150deg,#87CEFA 15%, #E6E6FA 70%,#fff 94%);
}
body.light h1 {
  color: black;
}
body.light h1::after {
  border-bottom: 0.4rem solid #000;
}
body.light h2 {
  color:  var(--lightH2);
}
body.light p {
  color: black;
}
body.light .navbar a {
  color: #fff;
  transition: 0.5s ease-out;
}
body.light .navbar a:hover {
  color: #00bfff;
}
body.light .navbar.menu-icon {
  color: #000;
}
body.light .nav-menu.active a {
  color: #fff;
}
body.light .nav-menu.active a:hover {
  color: #00bfff;
}
body.light .hero-text p {
  color: var(--toolText);
}
body.light .second-text {
  color: var(--lightH2);
}
body.light .mousey {
  border: 2px solid #000;
}
body.light .scroller {
  background-color: #000;
}
body.light .card {
  border: 1px solid #dcdcdc;
  transition: 0.5s ease-out;
}
body.light .contact-info .row a h2 {
  color: #000;
  transition: 0.5s ease-out;
}
body.light .contact-info .row a h2:hover {
  color:#00bfff;
  transition: 0.5s ease-out;
} */
.navbar a,
.contact-info .row a h2 {
  color: #fff;
  transition: 0.5s ease-out;
}
.modal p {
  color: #000;
}
.contact-info .subject a {
  color: #dcdcdc;
  color: var(--textColor);
}
.contact-info .row a h2:hover {
  color: #00bfff;
  transition: 0.5s ease-out;
}

/* Section */
.hero {
  height: auto;
  width: 100%;
  margin: 6rem 0 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
}
.about,
.portfolio,
.contact {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
}
.hero,
.about,
.contact {
  padding: 0 15%;
}
.about,
.portfolio {
  margin: 6rem 0 25rem 0;
}
.portfolio {
  padding: 0 10%;
}
.contact {
  margin: 6rem 0 12rem 0;
}

section img {
  height: 18rem;
}

h1 {
  color: #fff;
  color: var(--heroColor);
  font-size: 3rem;
}

h2 {
  color: #ffd700;
  color: var(--headColor);
}

p {
  color: #dcdcdc;
  color: var(--textColor);
  text-align: left;
  line-height: 2rem;
}

.navbar.nav-scroll {
  color: #fff;
  color: var(--btnText);
  background-color: #131313;
  background-color: var(--navScroll);
  opacity: 0.9;
}

a:hover {
  background-position: -100%;
  outline: none;
}

.head-container {
  text-align: center;
}

.head-container p {
  text-align: center;
  margin-top: 2rem;
}

.head-text::after {
  content: "";
  border-bottom: 0.4rem solid #fff;
  border-bottom: 0.4rem solid var(--heroColor);
  width: 2.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

@media (max-width: 950px) {
  .person-image {
    height: 15rem;
    margin: 0 auto;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  p {
    font-size: 0.9rem;
  }
}

/* Portfolio */
.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3.5rem;
  gap: 3.5rem;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.card {
  background: #fff;
  background: var(--cardColor);
  height: 350px;
  width: 350px;
  border: 3px solid #fff;
  border: 3px solid var(--cardColor);
  border-radius: 5% 0;
  /* opacity: 90%; */
  cursor: pointer;
}

.card:hover {
  background: #87cefa;
  background: var(--cardTitle);
  border: 3px solid #87cefa;
  border: 3px solid var(--cardTitle);
  box-shadow: 0 0 10px #719ece;
  transition: 0.5s ease-out;
}

.img-one {
  background: url(/static/media/apelinks.b96f1472.png) no-repeat;
  height: 300px;
  width: 100%;
  border-radius: 5% 0;
  background-size: 150%;
}

.img-two {
  background: url(/static/media/photography.86809ac3.png) no-repeat;
  height: 300px;
  width: 100%;
  border-radius: 5% 0;
  background-size: 150%;
}

.img-three {
  background: url(/static/media/teavies.42ff4b60.png) no-repeat;
  height: 300px;
  width: 100%;
  border-radius: 5% 0;
  background-size: 150%;
}

.img-four {
  background: url(/static/media/blog.652c93ea.png) no-repeat;
  height: 300px;
  width: 100%;
  border-radius: 5% 0;
  background-size: 150%;
}

.img-five {
  background: url(/static/media/boba.5b5b8472.png) no-repeat;
  height: 300px;
  width: 100%;
  border-radius: 5% 0;
  background-size: 150%;
}

.img-six {
  background: url(/static/media/collection.f2ed3839.png) no-repeat;
  height: 300px;
  width: 100%;
  border-radius: 5% 0;
  background-size: 150%;
}

.img-seven {
  background: url(/static/media/guide.ae03e916.png) no-repeat;
  height: 300px;
  width: 100%;
  border-radius: 5% 0;
  background-size: 150%;
}

.card .title {
  font-size: 1.5rem;
  font-weight: bold;
  height: 50px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 0 auto;
  margin-top: -45px;
}

@media (max-width: 1250px) {
  .card-container {
    display: flex;
    flex-wrap: wrap;
  }
}

body.active-modal {
    overflow-y: hidden;
  }

  .modal {
    z-index: 2;
  }

  .modal,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }

  .overlay {
    background: rgba(49, 49, 49, 0.8);
  }
  .modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #fff;
    line-height: 2rem;
    padding: 2rem 5rem;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
    border-radius: 10px;
  }

  .modal-content h2 {
    color: #dc143c;
    margin-bottom: 1rem;
  }

  .modal-content p {
    color: black;
  }

  .modal-content .modal-links button {
    background: var(--btnColor);
    width: 8rem;
    margin: 1.5rem 0.5rem 0 0;
    border: 1px solid var(--btnText);
    background-size: 200%;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.75rem;
    outline: none;
    cursor: pointer;
    border-radius: 50px;
    transition: 0.5s ease-out;
  }

  .modal-content .modal-links button:hover {
    color: var(--bgColor);
    background: #00bfff;
    border: 1px solid transparent;
  }

  .close-modal {
    background-color: transparent;
    border: none;
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    cursor: pointer;
  }

  .close-modal:hover {
    color: var(--heroColor2);
  }

  @media (max-width: 863px) {
    .modal-content {
      padding: 1.5rem 2.5rem;
    }
    .modal-content p {
      font-size: 0.89rem;
    }
    .modal-content p {
      font-size: 0.89rem;
    }
    .modal-content .modal-links button {
      font-size: 0.75rem;
      width: 5rem;
    }
  }
